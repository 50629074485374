export const environment = {
    production: true,
    e2e: false,
    firebase: {
        apiKey: "AIzaSyDgEVIMzIrQn6yAQ889DkDLKk87-H0Q3Yc",
        authDomain: "gcloudai.deloittecampfire.com",
        projectId: "cwl-53bca24db5a1-prod",
    },
    region: "europe-west1",
    brain_base_url: "https://gcloudai.api.deloitte.camp",
    authParams: ["google", "oidc.deloitte"],
    signInType: 'signInWithPopup',
    hasEnterpriseAssetSearch: false,
    showBarrierQuestions: false,
    skipPurchaseSuccess: true,
    skipDeploymentScreen: true,
    asset_redirect_uri: {},
    breadcrumbs: {
        assetOwner: {
            aoh: "Asset Owner Hub",
            assetManagement: "Asset Management",
            principleAssetQuestions: "Principle asset questions",
            offerRequestDetail: "Offer request detail",
            assetPreview: "Preview",
        },
        store: {
            deloitteAiCatalog: "Storefront",
            checkout: "Checkout",
            standingOrderDetail: "Standing order detail",
            storefront: "CAMPfire",
            orderHistory: "Order History",
            myEntitlements: "My Entitlements"
        }
    },
    aoh_url: "https://aoh.gcloudai.deloittecampfire.com/",
    store_url: "https://gcloudai.deloittecampfire.com/",
    api_base_url: "https://gcloudai.api.deloitte.camp",
    onboarding: {
        displayVideo: false,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    support: {
        ccEmail: "",
        contact: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace"
    },
    onboarding_options: {
        "gen_ai": {
            api_url: "https://gcloudai-geniusai.connect.api.deloitte.ai",
            app_url: "https://geniusai.gcloudai.deloittecampfire.com/home"
        },
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
